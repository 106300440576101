import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Workflow Builder",
  "author": "tzookb",
  "type": "post",
  "date": "2019-05-07T17:07:00.000Z",
  "url": "/2019/05/workflow-builder/",
  "categories": ["My Projects"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{` `}<strong parentName="p">{`Tech Stack:`}</strong>{` Javascript, React, SVG, webpack, css, html User interface for creating funnels with different actions, decisions and stats. Graph objects were drawn with SVG and wrapped with react for the logic.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      